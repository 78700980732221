import { Atom, state } from ":mods";
import {
  ShowSubmissionDetailsViewProps,
  ShowSubmissionStudentViewProps,
  ShowSubmissionsTableViewProps,
  SubmissionDetails,
  SubmissionsDetailsProps,
} from "../../../models";
import { For, Show, createEffect, createSignal } from "solid-js";
import { useParams } from "@solidjs/router";
import { ModulesSection } from "./modules-section";
import { ExhibitionSection } from "./exhibition-section";
import { getSubmissionsDetails, updateSubmissionsStatus } from "../../../apis";
import { PersonalSection } from "./personal-section/personal-section";
import { StudentSubmodule } from "../student-submodule";
import { SUBMISSION_STATUSES } from "../../../const";

export function ShowSubmissionDetails(props: ShowSubmissionDetailsViewProps) {
  const buttonStyle = "bg#p text-white py-10px px-20px cursor-pointer";
  const { id: stage_id, applicationID, statusID } = useParams();
  const [submissionDetailsDB, setSubmissionDetailsDB] = createSignal({} as SubmissionsDetailsProps);
  const $details = state.createAsync([getSubmissionsDetails, stage_id, applicationID]);
  const $show_submodule = state.create(undefined as Pick<ShowSubmissionStudentViewProps, "type" | "arr">);
  $details.on((event, { value }) => {
    setSubmissionDetailsDB($details.unwrap.value?.data);
  });

  createEffect(() => {
    if (!$show_submodule.value) {
      props.$.actions.setLayout({
        title: () => (
          <div
            class="underline text-0.7rem cursor-pointer"
            onclick={() => {
              props.$.actions.navigateDelta(-1);
            }}
          >
            {"< "}Back
          </div>
        ),
      });
    }
  });
  console.log("status id is :: ", statusID);
  async function onChangeSubmissionStatus(status: string, msg: string) {
    if (status === statusID) {
      props.$.actions.pushToast({
        type: "error",
        message: "you can't move the ticket to the same list!",
      });
      return;
    }
    await updateSubmissionsStatus(stage_id, applicationID, status)
      .then((res) => {
        console.log("moved ticket :: ", res);
        props.$.actions.pushToast({
          type: "success",
          message: "Ticket has been moved to " + msg + "successfully!",
        });
        props.$.actions.navigateDelta(-1);
      })
      .catch((e) => {
        console.log("rejected ticket :: ", e);
        props.$.actions.pushToast({
          type: "error",
          message: "there has been an issue moving this ticket to " + msg + "!",
        });
      });
  }
  return (
    <Show
      when={!$show_submodule.value}
      fallback={
        <StudentSubmodule
          {...$show_submodule.unwrap}
          $={props.$}
          applicationID={applicationID}
          events={{
            onBack: () => {
              $show_submodule.set(undefined);
            },
          }}
        />
      }
    >
      <section class="text-16px my-24px">
        <section class="flex justify-between" id="status-control-section">
          <p>
            Status of application:{" "}
            <span class="font-700">
              {submissionDetailsDB()?.application_avaliable_status?.[submissionDetailsDB().application_status]}
            </span>
          </p>
          <div class="flex gap-20px">
            <Atom.Form.Button
              controls={{}}
              statusValid="Pending"
              class={buttonStyle}
              onclick={() => onChangeSubmissionStatus(SUBMISSION_STATUSES.pending, "Pending")}
            />
            <Atom.Form.Button
              controls={{}}
              statusValid="Shortlist"
              class={buttonStyle}
              onclick={() => onChangeSubmissionStatus(SUBMISSION_STATUSES.shortlisted, "Shortlist")}
            />
            {window.location.pathname.includes("admin") && (
              <>
                <Atom.Form.Button
                  controls={{}}
                  statusValid="Approved"
                  class={buttonStyle}
                  onclick={() => onChangeSubmissionStatus(SUBMISSION_STATUSES.approved_for_in_person, "Approved")}
                />
                <Atom.Form.Button
                  controls={{}}
                  statusValid="Not Approved"
                  class={buttonStyle}
                  onclick={() => onChangeSubmissionStatus(SUBMISSION_STATUSES.approved_for_Advance, "Not Approved")}
                />
              </>
            )}
          </div>
        </section>
        <hr class="w-full h-2px bg-[#BDD2EA] mt-20px" />

        <section>
          <p class="text-25px font-700 my-40px">
            {submissionDetailsDB()?.header_details?.name} | {submissionDetailsDB()?.header_details?.region_city} |{" "}
            {submissionDetailsDB()?.header_details?.exhibition_title}
          </p>

          <ModulesSection
            modules_tasks={submissionDetailsDB()?.modules_tasks}
            modules_questions={submissionDetailsDB()?.modules_questions}
            events={{
              onViewTaskOrQuestion: (props) => {
                $show_submodule.set(props);
              },
            }}
          />

          <ExhibitionSection exhibitionData={submissionDetailsDB()?.exhibition} />
          <PersonalSection personalData={submissionDetailsDB()} />
        </section>
      </section>
    </Show>
  );
}
